/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import styled, { createGlobalStyle } from "styled-components"
import { DIMSUM } from "../styles/colors"
import BackgroundImage from 'gatsby-background-image'
import Bienvenida from "./bienvenida"
import getViewport from "../utils/get-viewport"

import Header from "./header"
import Footer from "./footer"
import "./layout.css"

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${DIMSUM.WHITE};
  }
`;

const HeaderWrapper = styled(BackgroundImage)`

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;

  section {
    align-self: end;
  }
  @media only screen and (min-width: 450px) {
    min-height: 480px;
  }

`;


const LayoutPortada = ({ children, siteName, tagline, className, imagen }) => {
  const viewport = getViewport();
  return (
    <>
      <GlobalStyle />
      <HeaderWrapper
        Tag="section"
        className={className}
        fluid={imagen[viewport]}
      >
        <Header siteTitle={siteName} />
        <Bienvenida>{tagline}</Bienvenida>
      </HeaderWrapper>
      <main className={className}>{children}</main>
      <Footer />
    </>
  )
}

LayoutPortada.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutPortada
