import React from "react"
import styled from "styled-components"

import LayoutPortada from "../components/layout_portada"
import SEO from "../components/seo"
import Seccion from "../components/seccion"

const SeccionesWrapper = styled.section``;


const IndexPage = ({ data, pathname }) => {
  const Portada = data.contentfulPortada;
  const SeccionesArray = [];
  var i = 0;

  const image = Portada.imagen ? Portada.imagen.social : null
  Portada.secciones.forEach(seccion =>{ 
    return(
    SeccionesArray.push(
    <Seccion
      tituloSeccion={seccion.titulo}
      idSeccion={seccion.id}
      trabajosSeccion={seccion.trabajos}
      key={i++}
      >
        {seccion.titulo}
      </Seccion>)
  )})
  return(
    <LayoutPortada
      siteName={Portada.nombre}
      siteLogo={Portada.logo.file.url}
      imagen={Portada.imagen}
      tagline={Portada.tagline}
      pathname={pathname}
    >
      <SEO 
        title="Dimsum.es" 
        keywords={[`gatsby`, `application`, `react`]} 
        image={image}
        />
      <SeccionesWrapper>
        {SeccionesArray}
      </SeccionesWrapper>
    </LayoutPortada>
  )
}

export default IndexPage

export const Portada = graphql`
  query PortadaPageQuery {
    contentfulPortada {
      tagline
      nombre
      imagen {
        mobile: fluid(quality: 90, maxWidth: 450) {
          base64
          aspectRatio
          src
          srcSet
          srcSetWebp
          sizes
        }
        tablet: fluid(quality: 90, maxWidth: 750) {
          base64
          aspectRatio
          src
          srcSet
          srcSetWebp
          sizes
        }
        desktop: fluid(quality: 90, maxWidth: 1920) {
          base64
          aspectRatio
          src
          srcSet
          srcSetWebp
          sizes
        }
        social: fixed(width: 1200) {
          src
          width
          height
        }
      }
      logo {
        file {
          url
        }
      }
      secciones {
        id
        titulo
        trabajos {
          titulo
          slug
          destacada {
            mobile: fluid(quality: 90, maxWidth: 450) {
              base64
              aspectRatio
              src
              srcSet
              srcSetWebp
              sizes
            }
            tablet: fluid(quality: 90, maxWidth: 750) {
              base64
              aspectRatio
              src
              srcSet
              srcSetWebp
              sizes
            }
            desktop: fluid(quality: 90, maxWidth: 1920) {
              base64
              aspectRatio
              src
              srcSet
              srcSetWebp
              sizes
            }
          }
        }
      }
    }
  }
`;