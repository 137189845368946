import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Link } from "gatsby"

import { DIMSUM } from "../styles/colors"
import { TYPOGRAPHY } from "../styles/typography"

import TrabajoThumb from "../components/trabajoThumb"
import getViewport from "../utils/get-viewport"

const DividerWrapper = styled.section`
  background-color: ${DIMSUM.YELLOW};
  padding-bottom: 24px;
`;

const SeccionWrapper = styled.div`
  background-color: ${DIMSUM.BLACK};
  color: ${DIMSUM.WHITE};
  min-height: 50vw;

  display: grid;
  grid-template-columns: 50vw 50vw;
  grid-gap: 0;
  grid-template-rows: 50vw;


  @supports (-webkit-touch-callout: none){
      letter-spacing: -0.05em;
  }

`;

const SeccionTitulo = styled.div`
  padding: 24px;
  grid-column: 1/2;
  grid-row: 1/2;
  z-index: 2;
  
  background-color: ${DIMSUM.BLACK};

  h2{
    font-family: ${TYPOGRAPHY.LUST.FONT_FAMILY};
    font-style: ${TYPOGRAPHY.LUST.FONT_STYLE.NORMAL};
    font-size: 16px;
    line-height: 20px;

    &:after {
      content: " ";
      display: block;
      background-color: ${DIMSUM.YELLOW};
      width: 36px;
      height: 2px;
      margin-top: 10px;
    }
  }

  @media only screen and (min-width: 450px) {
    margin: 24px;
    height: 204px;
    width: 204px;
    padding: 60px 24px 24px 24px;

    h2 {
      font-size: 20px;
      line-height: 22px;
      text-align: center;

      & :after {
        margin: 22px auto 0;
      }
    }
  }
`;

const TrabajoWrapper = styled.section`
  @media only screen and (min-width: 450px) {
    & :first-of-type {
      grid-column: 1/3;
      grid-row: 1/2;
      z-index: 1;
    }
  }
`;

const Seccion = ({ tituloSeccion, trabajosSeccion }) => {
  const TrabajosArray = [];
  const viewport = getViewport();
  var i=1;
  var main=true;

  trabajosSeccion.forEach(trabajo => {
      TrabajosArray.push(
        <TrabajoWrapper>
          <Link
            to={`/trabajos/${trabajo.slug}`}
            key={i++}
            
            >
            <TrabajoThumb
              idTrabajo={trabajo.id}
              tituloTrabajo={trabajo.titulo}
              imagenDestacada={trabajo.destacada[viewport]}
              main={main}
            />
          </Link>
        </TrabajoWrapper>
      );
      main=false;
  });

  return(
    <DividerWrapper>
      <SeccionWrapper>
      <SeccionTitulo>
        <h2>{tituloSeccion}</h2>
      </SeccionTitulo>
        {TrabajosArray}
      </SeccionWrapper>
  </DividerWrapper>
);}

export default Seccion;

Seccion.propTypes = {
    tituloSeccion: PropTypes.string,
    idSeccion: PropTypes.string
}

/*


        

        */