import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { DIMSUM } from "../styles/colors"
import { TYPOGRAPHY } from "../styles/typography"
import BackgroundImage from 'gatsby-background-image'

const Wrapper = styled.section`
    position:relative;
    height: 100%;
`;

const Cover = styled(BackgroundImage)`
    height:100%;
    min-height: 25vw;
    z-index: 1;
`;

const Shield = styled.section`
    height: 100%;
    width: 100%;
    z-index: 2;
    top: 0;
    position: absolute;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    opacity: 0;
    @media only screen and (min-width: 450px){
        & :hover {
            opacity: 1;

            .fondoShield {
                opacity: 0.6;
                transition: opacity .2s ease-out;
            }

            .tituloShield {
                transform: translate(0, 0);
                opacity: 1;
                transition: all .4s cubic-bezier(0.16, 1, 0.3, 1);
            }
        }
    }
`;

const FondoShield = styled.section`
    background-color: ${DIMSUM.BLACK};
    opacity: 0;
    grid-column: 1/2;
    grid-row: 1/2;
`;

const TituloTrabajo = styled.h3`
    color: ${DIMSUM.WHITE};
    font-family: ${TYPOGRAPHY.NEUE.FONT_FAMILY};
    font-weight: ${TYPOGRAPHY.NEUE.FONT_WEIGHT.BOLD};
    text-align: right;
    font-size: 18px;
    text-decoration: none;
    text-transform: uppercase;
    opacity: 1;

    grid-column: 1/2;
    grid-row: 1/2;
    z-index: 3;

    justify-self: center;
    align-self: center;
    margin: 0;

    @media only screen and (min-width: 450px) {
        font-size: ${props => props.main ? "36px" : "20px"};
        line-height: ${props => props.main ? "40px" :  "24px"};

        justify-self: end;
        align-self: start;
        margin: 48px;

    }
    transform: translate(0, -72px);
    opacity: 0;
`;

const TrabajoThumb = ({ className, idTrabajo, tituloTrabajo, imagenDestacada, main }) => {
    return(
    <Wrapper>
    <Shield
        main={main}
    >
        <FondoShield
            className={"fondoShield"}
         />
        <TituloTrabajo
            className={"tituloShield"}
            main={main}
        >
            {tituloTrabajo}
        </TituloTrabajo>
    </Shield>
    <Cover
        Tag="section"
        fluid={imagenDestacada}
        backgroundColor={`#000`}
        className={className}
    >
    </Cover>
    </Wrapper>
    
)};

export default TrabajoThumb;

TrabajoThumb.propTypes = {
    className: PropTypes.string,
}

