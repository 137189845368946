import React from "react"
import { TYPOGRAPHY } from "../styles/typography"
import styled from "styled-components"

const Wrapper = styled.section` 
    padding: 72px 72px 24px 24px;
`;
const Tagline = styled.h2`
    font-family: ${TYPOGRAPHY.NEUE.FONT_FAMILY};
    font-weight: ${TYPOGRAPHY.NEUE.FONT_WEIGHT.BOLD};
    font-style: ${TYPOGRAPHY.NEUE.FONT_STYLE.NORMAL};
    font-size: 32px;
    line-height: 32px;
    margin: 0;
`;

const Bienvenida = ({ children }) => {
    return (
        <Wrapper>
            <Tagline>{children}</Tagline>
        </Wrapper>
    )
};

export default Bienvenida